import * as React from 'react';

import Layout from '~components/layout';
import Seo from '~components/seo';

const OnSalePage = () => (
  <Layout>
    <Seo title='Membership' />
    Page membership
  </Layout>
);

export default OnSalePage;
